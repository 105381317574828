@import '../../abstracts/mixins';
@import '../../components/title';
@import '../../components/button';

.left__column {
    background-color: var(--toc-grey);
    border-radius: 15px;
    margin-bottom: 15px;

    &__categories {
        padding-top: 2rem;

        &__title {
            @include subTitle();

            &::before {
                font-family: $fontAwesomeFree;
                content: "\f00a"
            }
        }

        &__items {
            .tree {
                li {
                    position: relative;
                    margin: 1rem 0;

                    &>ul {
                        list-style: outside;
                        padding-inline-start: 3rem;

                        li {
                            ul {
                                li {
                                    a {
                                        font-size: 1.3rem;
                                    }
                                }
                            }
                        }
                    }

                    a {
                        display: block;
                        font-size: 1.8rem;
                        font-weight: bold;
                        line-height: 1.5;
                        color: var(--toc-brown);
                    }

                    span.grower {
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 0;
                        cursor: pointer;
                        font-family: $fontAwesomeFree;

                        &.OPEN,
                        &.CLOSE {
                            &:before {
                                content: "\f077";
                                display: block;
                                text-align: center;
                                line-height: 2.5rem;
                                font-weight: 900;
                                color: var(--toc-orange);
                            }
                        }

                        &.CLOSE:before {
                            content: "\f078";
                            color: silver;
                        }
                    }

                    & span.grower:hover+a,
                    a:hover,
                    a.selected {
                        color: var(--toc-orange);
                    }

                    li {
                        a {
                            line-height: 2.5rem;
                            font-weight: normal;
                            color: #777777;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {
        & {
            display: none;
        }
    }
}