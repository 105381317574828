@import '../abstracts/variables';
// -----------------------------------------------------------------------------
// This file contains all styles related to the title component.
// -----------------------------------------------------------------------------

@mixin title() {
    display: block;
    margin: auto;
    text-align: center;

    &>h2 {
        display: inline-flex;
        flex-direction: column;
        position: relative;
        text-transform: uppercase;
        color: var(--toc-orange);

        &>span {
            @include line();
            width: 100%;
            display: block;
            margin: auto;
            position: relative;

            &>small {
                position: relative;
                padding: 0 2rem;
                font-size: $title-small;
                background-color: var(--toc-white);
            }
        }

        &>strong {
            font-size: $title-strong;
            color: var(--toc-brown);
            line-height: 1;
        }

        @media (max-width : $breakpoint-lg) {
            &>span {
                &>small {
                    font-size: $title-small-responsive;
                }
            }

            &>strong {
                font-size: $title-strong-responsive;
            }
        }
    }
}

@mixin line() {
    &::before {
        content: '';
        display: flex;
        position: absolute;
        top: 50%;
        width: 100%;
        height: 0.1rem;
        background: var(--toc-orange);
    }
}

@mixin h1() {
    text-transform: uppercase;
    font-weight: 500;
    color: var(--toc-brown);

    &:after {
        display: block;
        margin-top: 1rem;
        content: " ";
        width: 10%;
        height: 1px;
        background-color: var(--toc-orange);
    }
}

@mixin h2() {
    text-transform: uppercase;
    line-height: 1;
    font-size: 4rem;
    color: var(--toc-brown);
    font-weight: 900;
    margin: 0;

    @media (max-width : $breakpoint-lg) {
        font-size: 3rem;
    }
}

@mixin subTitle() {
    text-transform: uppercase;
    line-height: 1;
    font-size: 2.4rem;
    color: var(--toc-brown);
    font-weight: 900;
    margin: 0;
}

@mixin sectionTitles() {
    @include subTitle();
    font-family: $font-01;
    font-size: 2rem !important;
    text-transform: inherit !important;
}

@mixin listTitle() {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.8rem;
    color: var(--toc-brown);
}

@mixin doubleTitle() {
    .article-title-text {
        @include flex-row-justify(center);
        height: 100%;

        p {
            margin: 0;
            text-transform: uppercase;

            span {
                line-height: normal;
                color: var(--toc-orange);

                small {
                    padding: 0;
                    font-size: 3rem;
                }

                b {
                    font-size: 5rem;
                    color: var(--toc-brown);
                    line-height: 1;
                }
            }
        }

        @media (max-width: $breakpoint-xxl) {
            p {
                span {
                    @include flex-column-justify(center);

                    small {
                        padding: 0;
                        font-size: 2.5rem;
                        text-align: center;
                    }

                    b {
                        font-size: 4rem;
                        color: var(--toc-brown);
                        line-height: 1;
                    }
                }
            }
        }
    }


}

@mixin payment() {
    display: block;
    margin: 0;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.5;
    color: var(--toc-payment);
}