/* ****************** COLORS VARIABLES ****************** */
$primary : #ef7d00;
$secondary : #35180C;
$tertiary: #27ace3;
$success: #2FB900;
$danger: #EB1724;

/* ****************** PATH IMAGES ****************** */
$imagesPath :"/themes/toc/img";

/* ****************** BREAKPOINTS RESPONSIVE ****************** */
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1620px;
$breakpoint-mobile :1200px;

/* ****************** FONT ****************** */
$fontAwesomeFree : 'Font Awesome 5 Free';
$fontAwesomeBrand : 'Font Awesome 5 Brands';
$lineAwesome : "Line Awesome Free";
$font-01: "Montserrat",
sans-serif;

/* ****************** FONT AWESOME ****************** */
$chevron-up : '\f077';
$chevron-down : '\f078';
$chevron-right : '\f054';
$chevron-left : '\f053';
$check : '\f00c';
$cross : '\f00d';
$plus : '\2b';
$minus : '\f068';
$required : '\2a';
$circle : '\f111';

/* ****************** TITLE ****************** */
$title-small : 3rem;
$title-strong : 5rem;
$title-small-responsive : 2rem;
$title-strong-responsive : 3.8rem;